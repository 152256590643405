import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';

import { NewsItem } from '../libs/news-item';
import { latestNewsUrl, NEWS_PAGE_PREFIX } from '../libs/news-helpers';

import NewsElement from '../components/one-news-section';
import { InterractiveSection } from "@chweb/commonui";
import WithMargins from '../components/with-margins';
import Pagination from '../components/pagination';

import { pageInfoByLocale, updateSEO } from '../queries/page-info-helpers';
import { localizeUrl } from '../libs/locale';
import { PageSEO } from '../libs/seo';
import { LocationInfo } from '../libs/location';
import { parseLocalDate } from "@chweb/commonlib";
import { PageInfoData } from '../queries/fragment-page-info';

interface MediaTemplateProps {
  data: MediaTemplateData,
  pageContext: {
    locale: string,
    limit: number,
    skip: number,
    pagesCount: number,
    newsCount: number,
    currentPage: number
  },
  location: LocationInfo
}

export default function MediaTemplate ({ data, pageContext, location }: MediaTemplateProps): ReactElement {
  const news = data.allMarkdownRemark.edges.map(e => {
    const isMoreLink = e.node.excerpt.replace(/<(?:.|\n)*?>/gm, '').endsWith('…');
    const newsData = new NewsItem(
      localizeUrl(`/${NEWS_PAGE_PREFIX}${e.node.fields.slug}`),
      e.node.excerpt,
      parseLocalDate(e.node.frontmatter.date),
      e.node.frontmatter.source,
      isMoreLink
    );
    if (e.node.frontmatter.title) {
      newsData.setTitle(e.node.frontmatter.title);
    }
    return <NewsElement content = { newsData } key = { newsData.getId() } />;
  });

  // disabling indexing on this page
  // individual news will be indexed instead
  const seo = PageSEO.fromLocation(location);
  seo.isDisableIndex = true;

  const pageInfo = pageInfoByLocale(data.page)[pageContext.locale];
  updateSEO(seo, pageInfo);
  return (
    <Layout locale = { pageContext.locale } location={location} seo = { seo }>
      <InterractiveSection title = { pageInfo.title } >
        <WithMargins margin="5%">
          { news }
          <Pagination
            baseUrl = { localizeUrl(latestNewsUrl(), pageContext.locale) }
            total = { pageContext.pagesCount }
            visibleCount = { 10 }
            active = { pageContext.currentPage }
          />
        </WithMargins>
      </InterractiveSection>
    </Layout>
  );
}

interface MediaTemplateData {
  allMarkdownRemark: {
    edges: {
      node: {
        frontmatter: {
          date: string,
          source: string,
          title: string
        },
        fields: {
          slug: string
        },
        excerpt: string
      }
    }[]
  },
  page: PageInfoData
}

export const mediaQuery = graphql`
query mediaQuery($skip: Int!, $limit: Int!, $slugs: [String]!) {
  allMarkdownRemark(
    filter: {
      fileAbsolutePath: {regex: ".+/media/[^/]*\\.md$/"},
      fields: { slug : {in: $slugs}}
    },
    sort: {fields: [frontmatter___date], order: DESC}
    skip: $skip
    limit: $limit
  )
  {
    edges {
      node {
        frontmatter {
          date
          source
          title
        }
        fields {
          slug
        }
        excerpt (pruneLength: 250, format: HTML)
      }
    }
  }
  page: pageIndexYaml (yamlId: {eq: "media"}) {
    ...PageInfo
  }
}`;
